.connect-message-window-flex-items {
  width: 100%;

  &.phone-dropdown {
    margin-top: 8px;
  }
}

.messages-wrapper {
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px 0 10px;
}
.inner-messages-wrapper {
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  height: 100%;
}

.connect-message-date-wrapper {
  display: flex;
  justify-content: center;

  .connect-message-date {
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-radius: 27px;
    color: #777a82;
    font-size: 12px;
    width: max-content;
    height: 38px;
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    place-content: center;
    margin: 10px 0 0 0;
  }
}

.connect-message-bubble-wrapper {
  max-width: 70%;
  margin-bottom: 10px;

  &.right {
    align-self: flex-end;
  }

  &.left {
    align-self: flex-start;
  }
}

.connect-message-bubble {
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 14px;

  span {
    word-break: break-word;
  }
}

.connect-message-bubble-time {
  margin-left: 10px;
  font-size: 12px;
  span {
    font-style: italic;
    margin-right: 8px;
  }
  small {
    font-size: 11px;
    color: #83878c;
  }
  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }
}

.chat-pane-title {
  border: 1px solid #dadbdd;
  border-radius: 11px;
  background-color: #ffffff;
  padding: 2px 10px;
}

.chat-pane-title-wrapper {
  display: flex;
  justify-content: center;
}
.warning-icon {
  font-size: 14px;
  color: #faad14;
  margin-right: 8px;
}

.bold-name {
  font-weight: 600;
}
