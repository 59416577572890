.infiniteScrollDiv {
  height: 100vh;
  overflow-y: auto;
}

.loader {
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
}
.loadingIcon {
  font-size: 20px;
}
