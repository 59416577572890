.candidateNoteWrapper {
  height: calc(100vh - 328px) !important;
  overflow-y: scroll;
}

.extendView {
  height: calc(100vh - 118px) !important;
}

.extendView > div {
  min-height: calc(100vh - 115px) !important;
}

.candidateNote {
  margin: 10px 10px 5px 10px;
}

.candidateHeader {
  display: flex;
  justify-content: space-between;
}

.noteSubject {
  margin-bottom: 10px;
  width: 100%;
}

.notesContext {
  width: 15%;
}

.selectClassName {
  width: 100% !important;
}

.dropdownClassName {
  width: 100% !important;
}

.noteEditor {
  :global {
    .ql-editor {
      height: 100px;
    }
  }
}

.noteSave {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.notePopup {
  cursor: pointer;
  width: 35px;
  margin-left: auto;
  i {
    font-size: 20px;
    margin-top: 4px;
  }
}
.noteView {
  margin: 0 10px 0 10px;
  padding-bottom: 10px;
}
