.jobList {
  margin: auto;
  padding: var(--body-content-padding);
}
.paginationContainer {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
  &.onlyPreviousNext {
    :global .ant-pagination {
      :global .ant-pagination-item-active ~ .ant-pagination-item {
        display: none;
      }
      :global .ant-pagination-jump-next {
        display: none;
      }
    }
  }
}
