.job-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .job-info-container {
    width: 45%;
    padding-right: 10px;
  }

  .job-subinfo {
    display: flex;

    .job-subinfo-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .action-container {
    display: flex;
    width: 55%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .scouting-agent-icon {
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #dadbdd;
      position: relative;
    }
  }

  // height: 52px;
  #settings-icon {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    color: rgba(7, 16, 26, 0.4);
    cursor: pointer;
  }

  .job-external-source-text {
    box-sizing: border-box;
    height: 18px;
    width: 14px;
    border: 1px solid #d3adf7;
    border-radius: 2px;
    background-color: #f9f0ff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
}

.jobtitle-status-wrapper {
  display: flex;
  align-items: center;
}

#title {
  color: #374251;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 28px;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#status {
  color: #4a4a4a;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  margin-left: 20px;
}

#status > .job-status {
  font-size: 14px;
  color: #374251;
  box-shadow: 0 0 0 0px;
  font-weight: 400;
  letter-spacing: 1px;
}

.job-status-dropdown.ant-select-dropdown {
  width: 180px !important;
}

#status > .job-status > .ant-select-selection,
#status > .job-status > .ant-select-selection:hover,
#status > .job-status > .ant-select-selection:active,
#status > .job-status > .ant-select-selection:focus {
  border: 0px;
  box-shadow: 0 0 0 0px;
}

.portal-activation-wrapper {
  display: flex;
}

.side-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  .active-icon {
    border: 1px solid var(--arya-accent-color) !important;
    position: relative;
    background: #f6ffed !important;
  }
}

.portal-activation {
  min-width: 85px;
  border: 1px solid #dadbdd;
  padding: 10px 6px 6px 6px;
  border-radius: 4px 0px 0px 4px;

  &:hover {
    border: 1px solid #dadbdd;
    box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.1);
    cursor: pointer;
  }
}

.portal-activation-wrapper {
  .no-left-border {
    border-radius: 0px;
  }
}

.quick-search-wrapper {
  padding: 10px;
  border: 1px solid #dadbdd;
  border-left: 0px;
  border-radius: 0px 4px 4px 0px;
  height: 46px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.1);
  }
}

.quick-search {
  height: 100%;
  width: 50px;
  background-color: #efefef;
  border: 0px;
  margin-left: 5px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}

.quick-search-icon {
  font-size: 20px;
  color: #b4b7ba;
}

.quick-search.ant-btn {
  padding: 0px;
}

.job-share-button-wrapper {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.job-edit-button {
  border-radius: 20px;
  align-self: center;
}

.more-wrapper {
  cursor: pointer;
  margin: auto;
}

.more {
  font-size: 20px;
  color: #83878c;
  margin: 2px 0px 0px 0px;
}

#client {
  color: #374251;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.46px;
  line-height: 22px;
}

#id {
  color: #374251;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
}

.bot-config,
.automation-config {
  font-size: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 30px;
  color: #83878c;
  cursor: pointer;

  .bot-icon,
  .automation-icon {
    margin: 2px 0px 0px 0px;
  }
}

.recruiterAi-icon {
  padding-top: 6px;
  cursor: pointer;
}

.bot-right-radius {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  padding: 2px 0;
}

.bot-config.bot-active,
.bot-config.bot-active:hover,
.bot-config.bot-active:focus {
  border: 1px solid var(--arya-accent-color) !important;
  position: relative;
  background: #f6ffed !important;
  border-radius: 4px;
  padding: 2px 0;
}

.bot-config {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  padding: 2px 0;
}

.automation-left-radius {
  border: 1px solid #dadbdd;
  border-radius: 4px;
}

.active-scouting-agent-icon,
.active-scouting-agent-icon:hover,
.active-scouting-agent-icon:focus {
  border: 1px solid var(--arya-accent-color) !important;
  border-radius: 4px;
  position: relative;
  background: #f6ffed !important;
}

.green-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  background: var(--arya-accent-color);
  right: -4px;
}

.job-settings-popover-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  font-size: 16px;
  color: #83878c;
}

.job-settings-popover-menu {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  align-content: center;
}

.search-again-button {
  border-radius: 20px;
  background-color: #13c26b;
}

.search-again-button-text {
  height: 34px;
  color: #ffffff;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
}

.sourcing-progress-indicator {
  width: fit-content;
}
