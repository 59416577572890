.portal-activation-wrapper {
  width: 85px;
}

.portal-activation {
  min-width : 85px;
  border: 1px solid #dadbdd;
  padding: 10px 6px 6px 6px;
  border-radius: 4px 0px 0px 4px;

  &:hover {
    border: 1px solid #dadbdd;
    box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.1);
    cursor: pointer;
  }
}

.portal-activation-wrapper {
  .no-left-border {
    border-radius: 0px;
  }
}

.bot-config {
  padding-left: 9px;
  padding-right: 9px;
  font-size: 15px;
  color: #9c9fa3;

  .bot-icon {
    margin: 5px 0px 0px 0px;
  }
}
