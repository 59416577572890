.connectNotificationContainer {
  max-height: 300px;
  overflow-y: auto;
}

.connectNotificationEmpty {
  height: 197px;
  width: 361px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connectNotificationEmptyIconWrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.connectNotificationIcon {
  padding: 0px !important;
}

.wiggle {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.connectNotificationEmptyTitle {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.connectNotificationEmptyDescription {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.navbarMenuItemIcon {
  padding: 15px;
  cursor: pointer;
}

.badgedIcon {
  font-size: 22px;
}

.goToConnect {
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}

.connectBadge {
  :global {
    .ant-badge-count {
      background: #9254de;
      box-shadow: none;
      height: 14px;
      width: 14px;
      line-height: 14px;
      padding: 1px 3px;
      font-size: 10px;
      border-radius: 4px;
    }
  }
}
