.saveSearchCriteriaModalContent {
  .saveSearchCriteriaModalDescription {
    color: rgba(7, 16, 26, 0.5);
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
  }
  .saveSearchCriteriaModalFooter {
    display: flex;
    margin-right: 7px;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
    .note {
      font-size: 11px;
    }
  }
  .destinationField {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: #1f2730;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
  }
  .fieldContainer {
    display: flex;
    gap:10px;
    .actionButtons {
      border: 1px solid #b4b7ba;
      border-radius: 20px;
      cursor: pointer;
      .actionButtonText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        height: 100%;
        gap:5px;
        .actionButtonInfoText {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .highlightedActionButtons {
      border-color: #13c26b;
      background-color: #f6ffed;
    }
  }
}
.saveSearchCriteriaModalTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 8px;
}
.saveSearchCriteriaModalContentTwo {
  .saveSearchCriteriaModalHeader {
    color: rgba(7, 16, 26, 0.9);

    font-size: 20px;
    font-weight: 500;
    margin-top: 28px;
    line-height: 28px;
  }
  .saveSearchCriteriaModalTwoDescription {
    height: 48px;
    width: 438px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 14px;
  }
  .saveSearchCriteriaModalTwoFooter {
    display: flex;
    justify-content: flex-end;
  }
}
.saveSearchCriteriaFailureModalContent {
  .saveSearchCriteriaFailureModalHeader {
    margin-top: 23px;
    line-height: 28px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
  }
  .saveSearchCriteriaFailureModalDescription {
    margin-top: 8px;
    font-size: 16px;
  }
  .saveSearchCriteriaFailureModalFooter {
    margin-top: 27px;
    margin-bottom: 6px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
}
