.aTSAuthenticationWrapper {
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.chooseYourATS {
  font-size: 16px;
  font-weight: 475;
  color: #000000;
}

.aTSWrapper {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 1.5px 4px -1px #0a090b12;
  height: 48px;
}

.aTSName {
  display: flex;
  align-items: center;
  gap: 6px;
}

.aTSNameText {
  font-size: 14px;
  font-weight: 400;
  color: #1f2730;
}

.authenticationButton {
  text-decoration: underline;
  font-size: 14px;
  color: #13c26b;
  &.authenticated {
    color: #f5222d;
  }
  &.disabled {
    color: grey;
    opacity: 0.5;
  }
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
}

.confirmationModal {
  top: 200px;
}
